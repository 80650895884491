import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';
import { LG } from '~/util/breakpointWidths';
import { formatPrice, formatPriceToNumber } from '~/util/format';
import { defineExpandedState } from '~/util/newLayoutHelpers';

export default function FinalValueCard({ cart }) {
  const windowSize = useSelector(({ layout }) => layout.windowSize);

  const [expanded, setExpanded] = useState(defineExpandedState(windowSize, LG));

  useEffect(() => {
    setExpanded(defineExpandedState(windowSize, LG));
  }, [windowSize]);

  return (
    <Card className="new-layout card">
      <CardBody className="new-layout card-body px-3 py-3">
        <Row
          className="w-100 mx-0 cursor-pointer"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <Col className="font-size-16 font-weight-600 px-0">
            <Row className="w-100 mx-0 justify-content-between">
              <div className="px-0">Valor Total</div>
              <div className="px-0">
                <IconButton
                  icon={`las la-${expanded ? 'angle-up' : 'angle-right'}`}
                  click={() => {
                    setExpanded(!expanded);
                  }}
                  color="gray-700"
                />
              </div>
            </Row>
          </Col>
        </Row>
        <Collapse isOpen={expanded}>
          <Row className="w-100 mx-0">
            {formatPriceToNumber(cart?.formatted_final_value) ? (
              <Col className="font-size-32 font-weight-700 text-black px-0 my-2">
                {cart?.formatted_final_value}
              </Col>
            ) : (
              <Col className="text-black font-size-18 font-weight-700 px-0 my-2">
                -
              </Col>
            )}
          </Row>
          {/* <Row className="w-100 mx-0 mb-2">
            <Col className="mb-2 px-0">
              <div className="font-size-14 font-weight-600 text-gray-600">
                Valor da Compra
              </div>
              <div className="font-size-14 font-weight-400 text-gray-600">
                {formatPrice(cart?.amount)}
              </div>
            </Col>
            </Row> */}
          <Row className="w-100 mx-0">
            <Col
              xs={cart?.giftback?.amount && cart.giftback.amount > 0 ? 6 : 12}
              className="mb-2 px-0"
            >
              <div className="font-size-14 font-weight-600 text-gray-600">
                Valor da Compra
              </div>
              <div className="font-size-14 font-weight-400 text-gray-600">
                {cart?.amount ? formatPrice(cart?.amount) : '-'}
              </div>
            </Col>
            {cart?.giftback?.amount && cart.giftback.amount > 0 && (
              <Col xs={6} className="mb-2 px-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Desconto Giftback
                </div>
                <div className="font-size-14 font-weight-400 text-red">
                  -
                  {cart?.giftback?.amount && cart.giftback.amount > 0
                    ? formatPrice(cart?.giftback?.amount)
                    : '-'}
                </div>
              </Col>
            )}
            {cart?.is_for_delivery && (
              <>
                <Col xs={6} className="mb-0 px-0">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    Frete Cobrado
                  </div>
                  <div className="font-size-14 font-weight-400 text-gray-600">
                    {Number(cart?.freight_user)
                      ? formatPrice(cart?.freight_user)
                      : '-'}
                  </div>
                </Col>
                <Col xs={6} className="mb-0 px-0">
                  <div className="font-size-14 font-weight-600 text-gray-600">
                    Custo de Frete
                  </div>
                  <div className="font-size-14 font-weight-400 text-gray-600">
                    {!cart?.return_delivery && !cart?.freight && '-'}

                    {cart?.return_delivery &&
                      cart?.freight &&
                      formatPrice(
                        (Number(cart?.return_freight) || 0) +
                          (Number(cart?.freight) || 0)
                      )}

                    {!cart?.return_delivery &&
                      cart?.freight &&
                      formatPrice(Number(cart?.freight) || 0)}
                  </div>
                </Col>
              </>
            )}
          </Row>
          {/* cart?.payment_way?.not_detailed && cart?.status > 0 && (
            <Row className="w-100 mx-0 mb-2">
              <Col className="mb-2 px-0">
                <div className="font-size-14 font-weight-600 text-gray-600">
                  Meio de Pagamento
                </div>
                <div className="font-size-14 font-weight-400 text-green">
                  {cart?.payment_way?.not_detailed}
                </div>
              </Col>
            </Row>
          ) */}
          {/* <Row className="w-100 mx-0">
            <Col xs={4} lg={3} className="px-0">
              <div className="font-size-14 font-weight-600 text-gray-600">
                Frete
              </div>
              <div className="font-size-14 font-weight-400 text-gray-700">
                {cart?.formatted_freight}
              </div>
            </Col>
            <Col xs={4} lg={3} className="px-0">
              <div className="font-size-14 font-weight-600 text-gray-600">
                Desconto
              </div>
              <div className="font-size-14 font-weight-400 text-gray-700">
                {cart?.formatted_discount}
              </div>
            </Col>
          </Row> */}
        </Collapse>
      </CardBody>
    </Card>
  );
}
